import { AuthService } from 'src/app/feature/auth/services/auth.service';
import { Injectable, inject } from '@angular/core';
import { Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionService } from 'src/app/feature/auth/services/permission.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  private permissionService = inject(PermissionService);
  constructor(private authService: AuthService, private router: Router) {}

  canMatch(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const token = this.authService.getToken();
    const userDetails = this.authService.getUser();
    const permissions = this.permissionService.getPermissions();
    if (
      (token &&
        route.data?.['permission'] &&
        permissions.includes(route.data['permission'])) ||
      userDetails?.isAdmin
    ) {
      return true;
    } else {
      this.authService.setIsAuthenticated();
      return this.checkAuth();
    }
  }

  async checkAuth() {
    await localStorage.clear();
    await sessionStorage.clear();
    this.router.navigate(['/auth']);
    return false;
  }
}
