import { Injectable } from '@angular/core';
import * as Crypto from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LocalstorageService {
  encryptData(data: any): string {
    return Crypto.AES.encrypt(
      JSON.stringify(data),
      Crypto.enc.Utf8.parse(environment.cryptoSecret),
      {
        mode: Crypto.mode.ECB,
      }
    ).toString();
  }

  decryptData(data: any): any {
    try {
      const bytes = Crypto.AES.decrypt(
        data,
        Crypto.enc.Utf8.parse(environment.cryptoSecret),
        {
          mode: Crypto.mode.ECB,
        }
      );
      return JSON.parse(bytes.toString(Crypto.enc.Utf8));
    } catch (error) {
      return null;
    }
  }

  /**
   *
   * @param key Key to store in localstorage
   * @param data Value(string/object/any) without JSON.stringify(). It will automatically do it.
   * @returns Stores encrypted value in localstorage
   */
  setItem(key: string, data: any) {
    return localStorage.setItem(key, this.encryptData(data));
  }

  /**
   *
   * @param key Key to get value from localstorage
   * @returns No need to do JSON.parse after retriving. It will do it automatically.
   */
  getItem(key: string) {
    return this.decryptData(localStorage.getItem(key));
  }

  /**
   *
   * @param key Key to remove from localStorage
   * @description This method is not necessary but if we implement encryption in key also then will need it.
   */
  removeItem(key: string) {
    localStorage.removeItem(key);
  }

  /**
   *
   * @param key Key to store in sessionStorage
   * @param data Value(string/object/any) without JSON.stringify(). It will automatically do it.
   * @returns Stores encrypted value in sessionStorage
   */
  setSessionItem(key: string, item: any) {
    sessionStorage.setItem(key, this.encryptData(item));
  }

  /**
   *
   * @param key Key to get value from sessionStorage
   * @returns No need to do JSON.parse after retriving. It will do it automatically.
   */
  getSessionItem(key: string) {
    return this.decryptData(sessionStorage.getItem(key));
  }
}
