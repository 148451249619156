import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { SafeHtmlPipe } from 'src/app/shared/pipes/safe-html.pipe';
import { AuthService } from 'src/app/feature/auth/services/auth.service';
import { icons } from 'src/assets/icon/svg';
import { CommonService } from '../../services/common.service';
import { SocketService } from '../../services/socket.service';
import { PermissionService } from 'src/app/feature/auth/services/permission.service';
import { PERMISSION } from 'src/app/core/enums/permission';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [SafeHtmlPipe, CommonModule, RouterModule],
})
export class HeaderComponent {
  private permissionService = inject(PermissionService);

  permisions = PERMISSION;

  public icons: any = icons;
  toggle: boolean = false;
  notificationCount: any;
  permissions!: string[];
  userDetails$ = this.authService.userDetails$;
  userDetails: any = this.authService.getUser();

  constructor(
    private authService: AuthService,
    private router: Router,
    private commonService: CommonService,
    private socketService: SocketService
  ) {
    this.socketService.connect();

    this.socketService
      .getSocket()
      .pipe(takeUntilDestroyed())
      .subscribe((notification: any) => {
        this.notificationCount = notification.new_unread_notification;
      });
  }

  ngOnInit() {
    this.permissions = this.permissionService.getPermissions();
  }

  async logOut() {
    this.authService.logOut().subscribe(async res => {
      if (res) {
        await localStorage.clear();
        await sessionStorage.clear();
        await this.authService.setIsAuthenticated();
        this.router.navigate(['/auth']);
      }
    });
  }

  goToSubAdmin() {
    this.router.navigate(['sub-admin']);
  }

  click() {
    this.toggle = !this.toggle;
    this.commonService.toggleChange(this.toggle);
  }
}
