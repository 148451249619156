import { AlertService } from './../../../../shared/services/alert.service';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { LoginResponse } from '../interfaces/auth-interface';
import { LocalstorageService } from 'src/app/shared/services/localstorage.service';
import { NgIf } from '@angular/common';
import { AutoFucosDirective } from '../../../../shared/directives/auto-fucos.directive';
import { PERMISSION } from 'src/app/core/enums/permission';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, AutoFucosDirective, NgIf, RouterLink],
})
export class LoginComponent {
  loginForm!: FormGroup;
  newPasswordForm!: FormGroup;

  passwordField!: boolean;
  rememberMe: boolean = false;
  newPasswordField!: boolean;
  confirmPasswordField!: boolean;
  oldPasswordField!: boolean;
  isSubAdmin: boolean = false;

  userDetails: any;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private alertService: AlertService,
    private localStorage: LocalstorageService
  ) {
    this.autoLogin();
    this.createLoginForm();
    this.createNewPasswordForm();
  }

  /**
   * This method will login user automatically if token is valid else user will be navigated to login page
   */
  autoLogin() {
    if (this.authService.getToken()) this.router.navigate(['/dashboard']);
  }

  createLoginForm() {
    this.loginForm = this.fb.group({
      email: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });
  }

  createNewPasswordForm() {
    this.newPasswordForm = this.fb.group({
      oldPassword: [null, [Validators.required]],
      newPassword: [null, [Validators.required, Validators.pattern(`^.{8,}$`)]],
      confirmPassword: [null, [Validators.required]],
    });
  }

  get formControls() {
    return this.loginForm.controls;
  }

  get passwordControls() {
    return this.newPasswordForm.controls;
  }

  login() {
    this.authService
      .login(this.loginForm.value)
      .subscribe(async (res: LoginResponse) => {
        if (res) {
          res.user.permissions.push(PERMISSION.common_permission);
          this.userDetails = res;
          if (!res.user.isAdmin) {
            if (!res.user.isPasswordUpdated) {
              this.isSubAdmin = true;
            } else {
              this.setUserDetails(res);
            }
          } else {
            this.setUserDetails(res);
          }
        }
      });
  }

  async setUserDetails(res: any) {
    const permissions = res.user?.isAdmin
      ? [...Object.values(PERMISSION)]
      : res.user.permissions;
    await this.alertService.success(res.message);
    await this.localStorage.setItem('rememberMe', this.rememberMe);
    await this.authService.setUser(
      this.rememberMe,
      res.user,
      res.authToken,
      permissions
    );
    await this.authService.setUserDetails(res.user);
    await this.authService.setIsAuthenticated();
    this.router.navigate(['dashboard']);
  }

  updatePassword() {
    const header = new HttpHeaders().set(
      'Authorization',
      `Token ${this.userDetails.authToken}`
    );
    this.authService
      .resetPassword(this.newPasswordForm.value, header)
      .subscribe(res => {
        if (res) {
          this.alertService.success('Password updated successfully!');
          this.setUserDetails(this.userDetails);
        }
      });
  }
}
