import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  ForgotPassword,
  Login,
  LoginResponse,
  LoginUserData,
  ResetPassword,
  ResponseMessage,
  UpdateProfileList,
} from '../component/interfaces/auth-interface';
import { LocalstorageService } from 'src/app/shared/services/localstorage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private baseURL: string = environment.baseURL;
  private userObj: any = this.getUser();

  isAuthenticatedSubject$ = new BehaviorSubject<boolean>(
    this.getToken() ? true : false
  );
  isAutenticated$ = this.isAuthenticatedSubject$.asObservable();

  private userDetailsSubject$ = new BehaviorSubject<any>(this.userObj);
  userDetails$ = this.userDetailsSubject$.asObservable();

  constructor(
    private http: HttpClient,
    private localStorage: LocalstorageService
  ) {}

  login(loginData: Login): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(
      `${this.baseURL}accounts/admin/login/`,
      loginData
    );
  }

  logOut(): Observable<null> {
    return this.http.post<null>(`${this.baseURL}accounts/admin/logout/`, null);
  }

  sendOtp(email: string): Observable<string> {
    return this.http.post<string>(
      `${this.baseURL}accounts/email/send-otp/`,
      email
    );
  }

  forgotPassword(data: ForgotPassword): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>(
      `${this.baseURL}accounts/admin/forget_password/`,
      data
    );
  }

  resetPassword(
    data: ResetPassword,
    header?: HttpHeaders
  ): Observable<ResponseMessage> {
    return this.http.put<ResponseMessage>(
      `${this.baseURL}accounts/admin/change-password/`,
      data,
      { headers: header }
    );
  }

  updateProfile(data: any, id: number): Observable<UpdateProfileList> {
    return this.http.put<UpdateProfileList>(
      `${this.baseURL}accounts/admin/${id}/`,
      data
    );
  }

  updateSubAdminProfile(data: any, id: number): Observable<UpdateProfileList> {
    return this.http.patch<UpdateProfileList>(
      `${this.baseURL}admins/sub-admin-users/${id}/`,
      data
    );
  }

  profileData(id: number): Observable<LoginUserData> {
    return this.http.get<LoginUserData>(`${this.baseURL}accounts/admin/${id}/`);
  }

  subAdminProfileData(id: number): Observable<LoginUserData> {
    return this.http.get<LoginUserData>(
      `${this.baseURL}admins/sub-admin-users/${id}/`
    );
  }

  setIsAuthenticated() {
    this.isAuthenticatedSubject$.next(this.getToken() ? true : false);
  }

  getToken() {
    if (this.remember) return this.localStorage.getItem('token');
    else return this.localStorage.getSessionItem('token');
  }

  setUserDetails(user: LoginUserData) {
    this.userDetailsSubject$.next(user);
  }

  setUser(
    rememberMe: boolean,
    user: LoginUserData,
    token?: string,
    permissions?: string[]
  ) {
    if (rememberMe) {
      this.localStorage.setItem('userDetails', user);
      token && this.localStorage.setItem('token', token);
      permissions && this.localStorage.setItem('permissions', permissions);
    } else {
      this.localStorage.setSessionItem('userDetails', user);
      token && this.localStorage.setSessionItem('token', token);
      permissions &&
        this.localStorage.setSessionItem('permissions', permissions);
    }
  }

  getUser() {
    if (this.remember) {
      return this.localStorage.getItem('userDetails');
    } else {
      return this.localStorage.getSessionItem('userDetails');
    }
  }

  get remember() {
    return this.localStorage.getItem('rememberMe');
  }
}
