import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { PERMISSION } from 'src/app/core/enums/permission';
import { LocalstorageService } from 'src/app/shared/services/localstorage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private baseURL: string = environment.baseURL;
  private localStorage = inject(LocalstorageService);

  constructor(private http: HttpClient) {}

  getPermissionList(): Observable<any> {
    return this.http.get<any>(`${this.baseURL}admins/permission-list/`);
  }

  setPermissions(role: string): any {
    if (role) {
      if (role === 'super_admin') {
        const permissions = [...Object.values(PERMISSION)];
        this.localStorage.setItem('permissions', JSON.stringify(permissions));
        return permissions;
      } else {
        this.localStorage.removeItem('permissions');
        return [];
      }
    }
  }

  hasPermission(action: any): boolean {
    const permissions = JSON.parse(
      this.localStorage.getItem('permissions') || ''
    );
    return permissions.includes(action);
  }

  get remember() {
    return this.localStorage.getItem('rememberMe');
  }

  getPermissions(): string[] {
    const permissions = this.remember
      ? this.localStorage.getItem('permissions')
      : this.localStorage.getSessionItem('permissions');
    if (permissions) {
      return permissions;
    }
    return [];
  }
}
