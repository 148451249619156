<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  [color]="primaryColor"
  [fullScreen]="true"
  name="loader"
  [template]="loaderTemplate">
</ngx-spinner>
<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  [color]="primaryColor"
  [fullScreen]="true"
  name="findVehicleLoader"
  [template]="findVehicleLoaderTemplate">
</ngx-spinner>
<div
  class="main-container"
  *ngIf="isAuthenticated$ | async; else unAuthenticated">
  <ng-container *ngIf="imagePreview$ | async as Image">
    <image-preview [src]="Image"></image-preview>
  </ng-container>
  <app-header></app-header>
  <div class="body-container">
    <div class="app-sidebar">
      <app-sidebar></app-sidebar>
    </div>
    <main class="main-body" [ngClass]="{ full: (toggle$ | async), rtl: isRTL }">
      <ng-container *ngIf="(open$ | async)?.visible">
        <app-modal></app-modal>
      </ng-container>
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
<ng-template #unAuthenticated> <router-outlet></router-outlet></ng-template>
