<div class="main-img-sec">
  <div class="bg-light"></div>
  <div class="image-sec d-flex justify-content-between py-3 px-4">
    <i (click)="closePreview()" [innerHTML]="icons['cross'] | safeHtml"></i>
    <i (click)="download()" [innerHTML]="icons['download'] | safeHtml"></i>
  </div>
  <ng-container *ngFor="let image of images; let i = index">
    <ng-container
      *ngIf="
        validImageExtensions.includes(image.type) && image.isActive;
        else image.isActive && pdfView
      ">
      <img
        [src]="image.url"
        alt="Image"
        onerror="image.url = 'assets/img/no-image.png'"
        [style.transform]="'rotate(' + image.rotation + 'deg)'"
        class="img-bg" />
      <ng-container [ngTemplateOutlet]="buttons"></ng-container>
    </ng-container>
    <ng-template #pdfView>
      <div class="view-pdf" (click)="viewPdf(image.url)">
        <img src="/assets/img/pdf-logo.png" alt="" />
      </div>
      <ng-container [ngTemplateOutlet]="buttons"></ng-container>
    </ng-template>

    <ng-template #buttons>
      <div class="image-control d-flex justify-content-around">
        <ng-container *ngIf="images.length !== 1">
          <i
            [ngClass]="{ hidden: i === 0 }"
            [innerHTML]="icons['menuArrow'] | safeHtml"
            (click)="previous(i)"></i>
          <i
            [ngClass]="{ hidden: i === images.length - 1 }"
            [innerHTML]="icons['menuArrow'] | safeHtml"
            (click)="next(i)"></i>
        </ng-container>
      </div>
      <div class="rotate-btn mt-3">
        <i
          [innerHTML]="icons['rotate'] | safeHtml"
          (click)="rotateImage(i)"></i>
      </div>
    </ng-template>
  </ng-container>
</div>
