<div class="sidebar" [ngClass]="{ hidden: (toggle$ | async) }" id="sidebar">
  <ul class="sidebar-menu">
    <li class="sidebar-menu-list" *ngFor="let item of menuList">
      <a
        [routerLink]="item.url"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: false }">
        <span class="menu-icon">
          <i [innerHTML]="icons[item.iconName] | safeHtml"></i>
        </span>
        <span class="menu-name">
          {{ item.menuName }}
        </span>
      </a>
    </li>
  </ul>
  <!-- Create Booking Button -->
  <!-- <div class="create-booking-wrap">
    <div class="create-icon">
      <i [innerHTML]="icons['create_booking'] | safeHtml"></i>
    </div>
    <a href="javascript:;" class="btn btn-create">Create Booking</a>
  </div> -->
</div>
