export enum PAGES {
  DASHBOARD = 'dashboard',
  BOOKINGS = 'bookings',
  RIDERS = 'riders',
  DISPATCHERS = 'dispatchers',
  DRIVERS = 'drivers',
  REQUESTS = 'requests',
  REPORTS = 'reports',
  PRICING_MODEL = 'pricing-model',
  FEEDBACK = 'feedback',
  PAYMENTS = 'payments',
  PROFILE = 'profile',
  NOTIFICATION = 'notification',
  SUB_ADMIN = 'sub-admin',
  SUBSCRIPTION = 'subscription',
  DISCOUNTS = 'discounts',
}

export enum PERMISSION {
  view_dashboard = 'view_dashboard',
  view_bookings = 'view_bookings',
  view_payment = 'view_payment',
  view_dispatchers = 'view_dispatchers',
  dispatchers_assign_driver = 'dispatchers_assign_driver',
  view_drivers = 'view_drivers',
  view_feedback = 'view_feedback',
  view_pricing_model = 'view_pricing_model',
  add_pricing_model = 'add_pricing_model',
  update_pricing_model = 'update_pricing_model',
  delete_pricing_model = 'delete_pricing_model',
  view_report = 'view_report',
  view_requests = 'view_requests',
  manage_requests = 'manage_requests',
  view_riders = 'view_riders',
  view_notification = 'view_notification',
  common_permission = 'common_permission',
  export_ride_booking_reports = 'export_ride_booking_reports',
  export_driver_reports = 'export_driver_reports',
  export_feedback_reports = 'export_feedback_reports',
  export_payment_reports = 'export_payment_reports',
  export_rider_reports = 'export_rider_reports',
  view_subscription_plan = 'view_subscription_plan',
  add_subscription_plan = 'add_subscription_plan',
  update_subscription_plan = 'update_subscription_plan',
  delete_subscription_plan = 'delete_subscription_plan',
  view_discount_coupon = 'view_discount_coupon',
  add_discount_coupon = 'add_discount_coupon',
  update_discount_coupon = 'update_discount_coupon',
  delete_discount_coupon = 'delete_discount_coupon',
  add_discounts = 'add_discounts',
  view_sos = 'view_sos',
  view_service_tax = 'view_service_tax',
  edit_service_tax = 'edit_service_tax',
}

export const DASHBOARD = [PERMISSION.view_dashboard];

export const BOOKINGS = [PERMISSION.view_bookings];

export const RIDERS = [PERMISSION.view_riders];

export const DISPATCHERS = [
  PERMISSION.view_dispatchers,
  PERMISSION.dispatchers_assign_driver,
];

export const DRIVERS = [PERMISSION.view_drivers];

export const REQUESTS = [PERMISSION.view_requests, PERMISSION.manage_requests];

export const REPORTS = [
  PERMISSION.view_report,
  PERMISSION.export_driver_reports,
  PERMISSION.export_feedback_reports,
  PERMISSION.export_payment_reports,
  PERMISSION.export_ride_booking_reports,
  PERMISSION.export_rider_reports,
];

export const PRICING_MODEL = [
  PERMISSION.view_pricing_model,
  PERMISSION.add_pricing_model,
  PERMISSION.update_pricing_model,
  PERMISSION.delete_pricing_model,
];

export const FEEDBACK = [PERMISSION.view_feedback];

export const PAYMENTS = [PERMISSION.view_payment];

export const NOTIFICATION = [PERMISSION.view_notification];

export const DISCOUNTS = [PERMISSION.view_discount_coupon];

export const SOS = [PERMISSION.view_sos];

export const SERVICE_TAX = [PERMISSION.view_service_tax];
