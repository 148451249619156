import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
export interface Modal extends NgbModalOptions {
  allowOutside?: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private titleSubject$ = new BehaviorSubject<string>('');
  title$ = this.titleSubject$.asObservable();

  private contentSubject$ = new BehaviorSubject<any>(null);
  content$ = this.contentSubject$.asObservable();

  private openSubject$ = new BehaviorSubject<any>(null);
  open$ = this.openSubject$.asObservable();

  private dataSubject$ = new BehaviorSubject<any>(null);
  data$ = this.dataSubject$.asObservable();

  constructor(private ngbModal: NgbModal) {}

  setTitle(title: string) {
    this.titleSubject$.next(title);
  }

  setContent(content: any) {
    this.contentSubject$.next(content);
  }

  setData(data: any) {
    this.dataSubject$.next(data);
  }

  open(options?: Modal) {
    const op: NgbModalOptions = {
      ...options,
      size: options?.size && `custom-${options?.size}`,
    };
    if (options?.allowOutside) {
      op.backdrop = op.keyboard = true;
    }
    this.openSubject$.next({ visible: true, options: op });
  }

  close() {
    this.ngbModal.dismissAll();
    this.openSubject$.next({ visible: false });
  }
}
