import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';
import { Component, inject } from '@angular/core';
import { icons } from '../../../../assets/icon/svg';
import { CommonService } from '../../services/common.service';
import { PERMISSION } from 'src/app/core/enums/permission';
import { PermissionService } from 'src/app/feature/auth/services/permission.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  standalone: true,
  imports: [SafeHtmlPipe, CommonModule, RouterModule],
})
export class SidebarComponent {
  private permissionService = inject(PermissionService);
  private commonService = inject(CommonService);

  icons = icons;
  permissions: string[] = [];
  menuList = [
    {
      menuName: 'Dashboard',
      iconName: 'dashboard',
      url: '/dashboard',
      permission: PERMISSION.view_dashboard,
    },
    {
      menuName: 'Bookings',
      iconName: 'calendar',
      url: '/bookings',
      permission: PERMISSION.view_bookings,
    },
    {
      menuName: 'Riders',
      iconName: 'userGroup',
      url: '/riders',
      permission: PERMISSION.view_riders,
    },
    {
      menuName: 'Dispatchers',
      iconName: 'dispatcher',
      url: '/dispatchers',
      permission: PERMISSION.view_dispatchers,
    },
    {
      menuName: 'Drivers',
      iconName: 'capBoy',
      url: '/drivers',
      permission: PERMISSION.view_drivers,
    },
    {
      menuName: 'Requests',
      iconName: 'documentPrevious',
      url: '/requests',
      permission: PERMISSION.view_requests,
    },
    {
      menuName: 'Reports',
      iconName: 'report',
      url: '/reports',
      permission: PERMISSION.view_report,
    },
    {
      menuName: 'Discounts',
      iconName: 'discounts',
      url: '/discounts',
      permission: PERMISSION.view_discount_coupon,
    },
    {
      menuName: 'Pricing Model',
      iconName: 'pricing',
      url: '/pricing-model',
      permission: PERMISSION.view_pricing_model,
    },
    {
      menuName: 'Feedback',
      iconName: 'feedback',
      url: '/feedback',
      permission: PERMISSION.view_feedback,
    },
    {
      menuName: 'Payments',
      iconName: 'card',
      url: '/payments',
      permission: PERMISSION.view_payment,
    },
    {
      menuName: 'Subscription',
      iconName: 'subscription',
      url: '/subscription',
      permission: PERMISSION.view_subscription_plan,
    },
    {
      menuName: 'Service Tax',
      iconName: 'serviceTax',
      url: '/service-tax',
      permission: PERMISSION.view_service_tax,
    },
    {
      menuName: 'Sos',
      iconName: 'sos',
      url: '/sos',
      permission: PERMISSION.view_sos,
    },
  ];
  toggle$ = this.commonService.toggleSidebar$;

  ngOnInit() {
    this.getUserPages();
  }

  getUserPages() {
    this.permissions = this.permissionService.getPermissions();
    this.menuList = this.menuList.filter((module: any) =>
      this.permissions.includes(module.permission)
    );
  }
}
