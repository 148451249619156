export interface Driver {
  id: number;
  profilePic: string;
  name: string;
  phoneNumber: string;
  email: string;
  city: string;
  driverDocument: DriverDocument;
  driverLicenseExpiryDate: string;
  driverLicenseNumber: string;
  isApproved: boolean;
  rating: boolean;
  totalEarning: number;
  totalTrips: number;
  dateOfBirth: string;
  status: string;
}

export interface DriverDocument {
  aadharCardImage: string[];
  bankAccountImage: string[];
  drivingLicenseImage: string[];
}
export interface DriverList {
  count: number;
  next: string | null;
  previous: string | null;
  results: Driver[];
}

export interface Car {
  id: number;
  name: string;
  type: string;
  seats: string;
  registrationNumber: string;
  fuelType: string;
  pucImage: string;
  insuranceImage: string;
  rcBookImage: string;
  permitImage: string;
  frontSideImage: string;
  chassisNumberImage: string;
  backSideImage: string;
  leftSideExteriorImage: string;
  rightSideExteriorImage: string;
  isApproved: boolean;
  driver: number;
}

export interface CarList {
  count: number;
  next: string | null;
  previous: string | null;
  results: Car[];
}

export interface ApprovedField {
  driverStatus: string;
}

export interface AcceptRequest {
  status: string;
}

export interface DeclineRequestCar {
  driverCar: number;
  id?: number;
  reason: string;
  resubmitDocument: string;
}

export interface DeclineRequestDriver {
  driver: number;
  id?: number;
  reason: string;
  resubmitDocument: string;
}
export interface DriverRequestParam {
  driverStatus?: string;
  limit?: number;
  offset?: number;
}
export interface CarRequestParam {
  status?: string;
  limit?: number;
  offset?: number;
}

export const validImageExtensions: string[] = ['jpg', 'jpeg', 'png'];
