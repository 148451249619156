import Swal, { SweetAlertOptions } from 'sweetalert2';
import { inject, Injectable } from '@angular/core';
import { from } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from './common.service';

type ToastType = 'success' | 'error' | 'warning';
type ToastColor = { [type in ToastType]: string };

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private translateService = inject(TranslateService);
  private commonService = inject(CommonService);
  isRTL: boolean = this.commonService.isRTL();
  private colors: ToastColor = {
    success: '#e6efe9',
    error: '#efe6e6',
    warning: '#efeee6',
  };
  private readonly primaryColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--theme-primary');

  private toast(type: ToastType) {
    this.translateService.onLangChange.subscribe(event => {
      this.isRTL = event.lang === 'ar'; // Update on language change
    });
    const Toast = Swal.mixin({
      toast: true,
      position:
        this.commonService.isRTL() || this.isRTL ? 'top-start' : 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      showCloseButton: true,
      background: this.colors[type],
    });
    return Toast;
  }

  success(title: string) {
    this.translateService.onLangChange.subscribe(event => {
      this.isRTL = event.lang === 'ar'; // Update on language change
    });
    this.toast('success').fire({
      icon: 'success',
      position:
        this.commonService.isRTL() || this.isRTL ? 'top-start' : 'top-end',
      title: this.translateService.instant(title),
    });
  }

  warning(title: string) {
    this.translateService.onLangChange.subscribe(event => {
      this.isRTL = event.lang === 'ar'; // Update on language change
    });
    this.toast('warning').fire({
      icon: 'warning',
      position:
        this.commonService.isRTL() || this.isRTL ? 'top-start' : 'top-end',
      title: this.translateService.instant(title),
    });
  }

  error(title: string) {
    this.translateService.onLangChange.subscribe(event => {
      this.isRTL = event.lang === 'ar'; // Update on language change
    });
    this.toast('error').fire({
      icon: 'error',
      position:
        this.commonService.isRTL() || this.isRTL ? 'top-start' : 'top-end',
      title: this.translateService.instant(title),
    });
  }

  /**
   *
   * @param body Pass body with type of `SweetAlertOptions`
   * @returns By default it returns promise with value but we have converted to observable with from operator to use easily in global level.
   */
  confirm(body?: SweetAlertOptions) {
    return from(
      Swal.fire({
        title:
          this.translateService.instant(
            (body?.title as string) || 'Are you sure?'
          ) || 'Are you sure?',
        text:
          this.translateService.instant(
            (body?.text as string) || "You won't be able to revert this!"
          ) || "You won't be able to revert this!",
        icon:
          this.translateService.instant((body?.icon as string) || 'warning') ||
          'warning',
        html: body?.html || '',
        showCancelButton: body?.showCancelButton || true,
        confirmButtonColor: body?.confirmButtonColor || this.primaryColor,
        confirmButtonText:
          this.translateService.instant(
            (body?.confirmButtonText as string) || 'Continue'
          ) || 'Continue',
        cancelButtonText:
          this.translateService.instant(
            (body?.cancelButtonText as string) || 'Cancel'
          ) || 'Cancel',
        allowOutsideClick: body?.allowOutsideClick || false,
        customClass: {
          cancelButton: 'alert-cancel',
          confirmButton: 'alert-confirm',
        },
      })
    );
  }
}
