import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';
import { ModalService } from './../../services/modal.service';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalConfig,
  NgbModalOptions,
} from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from 'src/app/feature/auth/component/login/login.component';
import { icons } from 'src/assets/icon/svg';
import { takeUntil, Subject } from 'rxjs';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [CommonModule, SafeHtmlPipe, NgSelectModule],
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  providers: [NgbActiveModal],
})
export class ModalComponent {
  instance = LoginComponent;
  icons = icons;

  title$ = this.modalService.title$;
  content$ = this.modalService.content$;
  destroyed$ = new Subject();

  @ViewChild('content', { static: false })
  content!: TemplateRef<HTMLTemplateElement>;

  constructor(
    config: NgbModalConfig,
    private ngbModal: NgbModal,
    private modalService: ModalService
  ) {
    config.backdrop = 'static';
    config.keyboard = false;

    this.modalService.open$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res: { visible: boolean; options: NgbModalOptions }) => {
        if (res?.visible)
          setTimeout(() => {
            this.open(res.options);
          }, 0);
        if (!res.visible) {
          this.close();
        }
      });
  }

  open(config: NgbModalOptions) {
    this.ngbModal.open(this.content, config).result.then(undefined, reason => {
      this.modalService.close();
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const modal = document.getElementsByClassName('modal-dialog')[0];
      const element = Array.from(modal.classList).find(item =>
        item.includes('modal-custom-')
      );
      modal.setAttribute(
        'style',
        `min-width:${element?.split('modal-custom-')[1] + 'px' || '100%'}`
      );
      modal
        .querySelector('.modal-content')
        ?.setAttribute(
          'style',
          `width:${element?.split('modal-custom-')[1] + 'px' || '100%'}`
        );
    }, 0);
  }

  close() {
    this.ngbModal.dismissAll('Close click');
  }

  ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }
}
