import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private toogleSidebarSubject$ = new BehaviorSubject<boolean>(false);
  toggleSidebar$ = this.toogleSidebarSubject$.asObservable();

  toggleChange(event: any) {
    this.toogleSidebarSubject$.next(event);
  }

  getPrimaryColor(): string {
    const color = getComputedStyle(document.documentElement).getPropertyValue(
      '--theme-primary'
    );
    return color;
  }

  getChartSecondaryColor(): string {
    const color = getComputedStyle(document.documentElement).getPropertyValue(
      '--theme-chart-secondary'
    );
    return color;
  }
}
