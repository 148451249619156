import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { filter, map, Observable } from 'rxjs';
import { AuthService } from 'src/app/feature/auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private socket$: WebSocketSubject<any>;

  constructor(private authService: AuthService) {
    this.socket$ = webSocket(
      environment.socketKey +
        'notification/' +
        '?Authorization=Token ' +
        this.authService.getToken()
    );
  }

  public connect() {
    return this.socket$.subscribe();
  }

  public getSocket(): Observable<any> {
    return this.socket$.asObservable();
  }

  getTrackingSocket(rideId: any) {
    const myWebSocket: WebSocketSubject<any> = webSocket(
      environment.socketKey + `ride/location/${rideId}/`
    );
    return myWebSocket.pipe(
      map((e: any) => {
        return e;
      })
    );
  }
}
