import { Directive, ElementRef } from '@angular/core';
import { map, of, take } from 'rxjs';

@Directive({
  selector: '[appAutoFocus]',
  standalone: true,
})
export class AutoFucosDirective {
  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    of(this.el)
      .pipe(
        map(elementRef => elementRef.nativeElement),
        take(1) // Unsubscribe to avoid memory leak
      )
      .subscribe(input => {
        input.focus();
      });
  }
}
